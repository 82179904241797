import React from 'react';
import { string, func, bool } from 'prop-types';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';

import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { lazyLoadWithDimensions } from '../../util/uiHelpers';
import { propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing, ensureUser } from '../../util/data';
import { richText } from '../../util/richText';
import { createSlug } from '../../util/urlHelpers';
import { formatCurrency, calcAge, truncateText } from '../../util/formatHelpers';
import { isBookingProcessAlias } from '../../transactions/transaction';
import Verified from '../../assets/icons/verified.png';
import TopChoices from '../../assets/badges/top_choices.png';

import { AspectRatioWrapper, NamedLink, ResponsiveImage } from '../../components';

import css from './ListingCard.module.css';

const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const LazyImage = lazyLoadWithDimensions(ResponsiveImage, { loadAfterInitialRendering: 3000 });

const PriceMaybe = props => {
  const { price, publicData, config, intl } = props;
  const { listingType } = publicData || {};
  const validListingTypes = config.listing.listingTypes;
  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice && price) {
    return null;
  }

  const isBookable = isBookingProcessAlias(publicData?.transactionProcessAlias);
  const { formattedPrice, priceTitle } = priceData(price, config.currency, intl);
  return (
    <div className={css.price}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      {isBookable ? (
        <div className={css.perUnit}>
          <FormattedMessage id="ListingCard.perUnit" values={{ unitType: publicData?.unitType }} />
        </div>
      ) : null}
    </div>
  );
};

export const ListingCardComponent = props => {
  const config = useConfiguration();
  const {
    className,
    rootClassName,
    intl,
    listing,
    renderSizes,
    setActiveListing,
    showAuthorInfo,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, publicData, description } = currentListing.attributes;
  const slug = createSlug(title);
  const author = ensureUser(listing.author);
  const authorName = author.attributes.profile.displayName;
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const setActivePropsMaybe = setActiveListing
    ? {
        onMouseEnter: () => setActiveListing(currentListing.id),
        onMouseLeave: () => setActiveListing(null),
      }
    : null;

  const checkMarketingChannel = () => {
    if (publicData && publicData.marketingChannel && publicData.marketingChannel.length > 1) {
      return `${publicData.marketingChannel[0]}, + ${publicData.marketingChannel.length - 1} more`;
    } else {
      return publicData.marketingChannel;
    }
  };

  return (
    <NamedLink className={classes} name="ListingPage" params={{ id, slug }}>
      <div className={css.container}>
        <div className={css.imageContainer}>
          <AspectRatioWrapper
            className={css.aspectRatioWrapper}
            width={aspectWidth}
            height={aspectHeight}
            {...setActivePropsMaybe}
          >
            <LazyImage
              rootClassName={css.rootForImage}
              alt={title}
              image={firstImage}
              variants={variants}
              sizes={renderSizes}
            />
          </AspectRatioWrapper>
        </div>
        <div className={css.descriptionContainer}>
          <div className={css.info}>
            {/* <PriceMaybe price={price} publicData={publicData} config={config} intl={intl} /> */}
            <div className={css.mainInfo}>
              <div className={css.title}>
                {truncateText(title, 40)}
                <img
                  hidden={!listing.attributes.metadata.verified}
                  className={css.verified}
                  src={Verified}
                  alt="Verified"
                />
                <span>
                  <img
                    className={css.topChoices}
                    src={TopChoices}
                    alt="Top Choices"
                    hidden={listing.attributes.metadata.priority >= 1000 ? false : true}
                  />
                </span>
              </div>
              <div className={css.description}>{truncateText(description, 100)}</div>
              <div className={css.tagsContainer}>
                <div className={css.tags}>{publicData.industry}</div>
                <div className={css.tags}>{publicData.technology}</div>
                <div className={css.tags}>{publicData.e_commerce_type}</div>
                <div className={css.tags}>{calcAge(publicData.age)}</div>
              </div>
              <div className={css.tagsContainer}>
                <div>
                  <div className={css.subtitle}>Avg. profit margin</div>
                  <div className={css.subDetails}>{publicData.average_profit_margin}%</div>
                </div>
                <div>
                  <div className={css.subtitle}>Turnover rate</div>
                  <div className={css.subDetails}>
                    {formatCurrency(publicData.last_month_turnover)}€ /yr
                  </div>
                </div>
                <div>
                  <div className={css.subtitle}>Marketing Channel</div>
                  <div className={css.subDetails}>{checkMarketingChannel()}</div>
                </div>
              </div>
              <div className={css.price}>
                {/* {price.currency} {price.amount} */}
                {priceData(price, config.currency, intl).formattedPrice}
              </div>
            </div>
          </div>
        </div>
      </div>
    </NamedLink>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  setActiveListing: null,
  showAuthorInfo: true,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,
  showAuthorInfo: bool,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
