/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// Note: The listingFields come from listingFields asset nowadays by default.
//       To use this built-in configuration, you need to change the overwrite from configHelper.js
//       (E.g. use mergeDefaultTypesAndFieldsForDebugging func)

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - includeForListingTypes:        An array of listing types, for which the extended
 *   (optional)                     data is relevant and should be added.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
export const listingFields = [
  {
    key: 'industry',
    scope: 'public',
    schemaType: 'enum',
    listingTab: 'details',
    enumOptions: [
      { option: 'Fashion', label: 'Fashion' },
      { option: 'Electronics', label: 'Electronics' },
      { option: 'Home Goods', label: 'Home Goods' },
      { option: 'Sports & Fitness', label: 'Sports & Fitness' },
      { option: 'Beauty & Personal Care', label: 'Beauty & Personal Care' },
      { option: 'AccessoriesJewelry', label: 'Accessories & Jewelry' },
      { option: 'Pets', label: 'Pets' },
      { option: 'Home Improvement', label: 'Home Improvement' },
      { option: 'Toys & Games', label: 'Toys & Games' },
      { option: 'Others', label: 'Others' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Industry',
      group: 'primary',
    },
    showConfig: {
      label: 'Industry',
      isDetail: true,
    },
    saveConfig: {
      label: 'Industry',
      placeholderMessage: 'Select options',
      isRequired: true,
      requiredMessage: 'You need to select an industry.',
    },
  },
  //  ****************************************************

  {
    key: 'e_commerce_type',
    scope: 'public',
    schemaType: 'enum',
    listingTab: 'details',
    enumOptions: [
      { option: 'Dropshipping Asia', label: 'Dropshipping Asia' },
      { option: 'Dropshipping Europe', label: 'Dropshipping Europe' },
      { option: 'Own Warehouse', label: 'Own Warehouse' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'E-Commerce type',
      group: 'primary',
    },
    showConfig: {
      label: 'E-Commerce type',
      isDetail: true,
    },
    saveConfig: {
      label: 'E-Commerce type',
      placeholderMessage: 'Select options',
      isRequired: true,
      requiredMessage: 'You need to select an e-commerce type.',
    },
  },
  //  ****************************************************

  {
    key: 'technology',
    scope: 'public',
    schemaType: 'enum',
    listingTab: 'details',
    enumOptions: [
      { option: 'Shopify', label: 'Shopify' },
      { option: 'WooCommerce', label: 'WooCommerce' },
      { option: 'Magento', label: 'Magento' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectMultipleFilter',
      label: 'Technology',
      group: 'primary',
    },
    showConfig: {
      label: 'Technology',
      isDetail: true,
    },
    saveConfig: {
      label: 'Technology',
      placeholderMessage: 'Select options',
      isRequired: true,
      requiredMessage: 'You need to select a technology.',
    },
  },
  //  ****************************************************

  {
    key: 'price',
    schemaType: 'enum',
    enumOptions: [
      { option: '0to500001', label: '0-5k' },
      { option: '500000to2500001', label: '5k-25k' },
      { option: '2500000to5000001', label: '25k-50k' },
      { option: '5000000to15000001', label: '50k-150k' },
      { option: '15000000to50000001', label: '150k-500k' },
      { option: '50000000to100000001', label: '500k-1mio' },
      { option: '100000000to', label: '1mio+' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectSingleFilter',
      label: 'Price',
      group: 'primary',
    },
  },
  //  ****************************************************

  {
    key: 'age',
    scope: 'public',
    listingTab: 'details',
    schemaType: 'long',
    showConfig: {
      label: 'Age',
    },
    saveConfig: {
      label: 'Business age (eg: 24 months)',
      placeholderMessage: 'Number of months',
    },
  },
  {
    key: 'age',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '0to7', label: '0-6 mo' },
      { option: '6to13', label: '6-12 mo' },
      { option: '12to37', label: '1-3 Jahre' },
      { option: '36to73', label: '3-6 Jahre' },
      { option: '72to109', label: '6-9 Jahre' },
      { option: '108to', label: '9+ Jahre' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectSingleFilter',
      label: 'Business age',
      group: 'primary',
    },
  },
  //  ****************************************************

  {
    key: 'last_12_months_turnover',
    scope: 'public',
    listingTab: 'details',
    schemaType: 'long',
    showConfig: {
      label: 'Last 12 months turnover',
    },
    saveConfig: {
      label: 'Last 12 months turnover',
      placeholderMessage: '€ 0.00',
    },
  },
  {
    key: 'last_12_months_turnover',
    scope: 'public',
    schemaType: 'enum',
    enumOptions: [
      { option: '0to25001', label: '0-25k' },
      { option: '25000to100001', label: '25k-100k' },
      { option: '100000to250001', label: '100k-250k' },
      { option: '250000to500001', label: '250k-500k' },
      { option: '500000to1000001', label: '500k-1 Mio' },
      { option: '1000000to5000001', label: '1-5 Mio' },
      { option: '5000000to', label: '5+ mio' },
    ],
    filterConfig: {
      indexForSearch: true,
      filterType: 'SelectSingleFilter',
      label: 'Turnover',
      group: 'primary',
    },
  },
  //  ****************************************************

  {
    key: 'last_month_turnover',
    scope: 'public',
    listingTab: 'details',
    schemaType: 'long',
    showConfig: {
      label: 'Last month turnover',
    },
    saveConfig: {
      label: 'Last month turnover',
      placeholderMessage: '€ 0.00',
    },
  },
  //  ****************************************************

  // can remove the below if unused in the future

  {
    key: 'average_profit_margin',
    scope: 'public',
    listingTab: 'details',
    schemaType: 'long',
    showConfig: {
      label: 'Average profit margin (%)',
    },
    saveConfig: {
      label: 'Average profit margin (%)',
      placeholderMessage: '0',
    },
  },

  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   includeForListingTypes: ['product-selling'],
  //   schemaType: 'text',
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   includeForListingTypes: ['daily-booking'],
  //   schemaType: 'text',
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: The listingTypes come from listingTypes asset nowadays by default.
//         To use this built-in configuration, you need to change the overwrite from configHelper.js
//         (E.g. use mergeDefaultTypesAndFieldsForDebugging func)
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:         Unique string. This will be saved to listing's public data on
 *                        EditListingWizard.
 * - label                Label for the listing type. Used as microcopy for options to select
 *                        listing type in EditListingWizard.
 * - transactionType      Set of configurations how this listing type will behave when transaction is
 *                        created.
 *   - process              Transaction process.
 *                          The process must match one of the processes that this client app can handle
 *                          (check src/util/transactions/transaction.js) and the process must also exists in correct
 *                          marketplace environment.
 *   - alias                Valid alias for the aforementioned process. This will be saved to listing's
 *                          public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType             Unit type is mainly used as pricing unit. This will be saved to
 *                          transaction's protected data.
 *                          Recommendation: don't use same unit types in completely different processes
 *                          ('item' sold should not be priced the same as 'item' booked).
 * - stockType            This is relevant only to listings using default-purchase process.
 *                        If set to 'oneItem', stock management is not showed and the listing is
 *                        considered unique (stock = 1).
 *                        Possible values: 'oneItem' and 'multipleItems'.
 *                        Default: 'multipleItems'.
 * - defaultListingFields These are tied to transaction processes. Different processes have different flags.
 *                        E.g. default-inquiry can toggle price and location to true/false value to indicate,
 *                        whether price (or location) tab should be shown. If defaultListingFields.price is not
 *                        explicitly set to _false_, price will be shown.
 *                        If the location or pickup is not used, listing won't be returned with location search.
 *                        Use keyword search as main search type if location is not enforced.
 *                        The payoutDetails flag allows provider to bypass setting of payout details.
 *                        Note: customers can't order listings, if provider has not set payout details! Monitor
 *                        providers who have not set payout details and contact them to ensure that they add the details.
 */

export const listingTypes = [
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  {
    listingType: 'product-selling',
    label: 'Sell bicycles',
    transactionType: {
      process: 'default-purchase',
      alias: 'default-purchase/release-1',
      unitType: 'item',
    },
    stockType: 'oneItem',
    defaultListingFields: {
      shipping: false,
      pickup: false,
      payoutDetails: false,
    },
  },
  // {
  //   listingType: 'inquiry',
  //   label: 'Inquiry',
  //   transactionType: {
  //     process: 'default-inquiry',
  //     alias: 'default-inquiry/release-1',
  //     unitType: 'inquiry',
  //   },
  //   defaultListingFields: {
  //     price: false,
  //     location: true,
  //   },
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
